import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@core/models/interfaces/api/contact.interface';
import { ContactService } from '@core/api/contact/contact.service';

@Component({
  selector: 'shared-contact-card-secondary',
  templateUrl: './contact-card-secondary.component.html',
  styleUrls: ['./contact-card-secondary.component.scss'],
})
export class ContactCardSecondaryComponent implements OnInit {

  @Input()
  public display: string;

  public contact: Contact;
  public loaded = false;

  constructor(
    private readonly contactService: ContactService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.contact = (await this.contactService.list())?.[this.display];
    this.loaded = true;
  }

}
