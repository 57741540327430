import { Component, OnInit } from '@angular/core';
import { Contact } from '@core/models/interfaces/api/contact.interface';
import { ContactService } from '@core/api/contact/contact.service';
import { Image } from '@core/models/datatypes/image.interface';
import { getBestImage, getMediaFormatUrl } from '@core/models/utils/media.utils';

@Component({
  selector: 'shared-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent implements OnInit {

  public generalContact: Contact;
  public contactImageUrl: string;
  public loaded = false;

  private FALLBACK_IMAGE = 'https://via.placeholder.com/210x300.png';

  constructor(
    private readonly contactService: ContactService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.generalContact = (await this.contactService.list())?.contactGeneral;
    this.contactImageUrl = this.getBestImageUrl(this.generalContact?.picture);
    this.loaded = true;
  }

  private getBestImageUrl(image: Image): string {
    const bestImage = getBestImage(image, 'small');

    return getMediaFormatUrl(bestImage) ?? this.FALLBACK_IMAGE;
  }

}
