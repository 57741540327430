import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ContactCardComponent } from './contact-card/contact-card.component';
import { ContactCardSecondaryComponent } from '@shared/components/contact-card-secondary/contact-card-secondary.component';
import { CenterInfoComponent } from '@shared/components/center-info/center-info.component';

const APP_COMPONENTS = [
  ContactCardComponent,
  ContactCardSecondaryComponent,
  CenterInfoComponent,
];

@NgModule({
    declarations: [
        APP_COMPONENTS,
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        APP_COMPONENTS,
    ]
})
export class ComponentsModule {}
