// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.center-info-component {
  width: 100%;
  position: relative;
}
div.center-info-component aside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
  text-align: center;
}
div.center-info-component ion-text {
  font-size: 2em;
}
div.center-info-component ion-icon {
  display: block;
  font-size: 6em;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/center-info/center-info.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAEA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,YAAA;EACA,kBAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,cAAA;EACA,cAAA;AAHJ","sourcesContent":["div.center-info-component {\n  width: 100%;\n  position: relative;\n\n  aside {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    opacity: 0.5;\n    text-align: center;\n  }\n\n  ion-text {\n    font-size: 2em;\n  }\n\n  ion-icon {\n    display: block;\n    font-size: 6em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
