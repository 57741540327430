import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Logger } from '@core/logger/logger.service';
import { I18nService } from '@shared/i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@core/storage/storage.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { environment } from '@env/environment';
import { register } from 'swiper/element/bundle';

const log = new Logger('AppComponent');
register();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly i18nService: I18nService,
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('Initializing app...');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
      );

    StatusBar.setStyle({
      style: Style.Dark,
    })
      .catch((e: any) => log.error('Encountered error while setting status bar style:', e));

    await this.storageService.init();

    this.platform.ready().then(async () => {
      await SplashScreen.hide();

      await this.authenticationService.validateLogin();
    });
  }

  public ngOnDestroy(): void {
    this.i18nService.destroy();
  }

}
