import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-center-info',
  templateUrl: './center-info.component.html',
  styleUrls: ['./center-info.component.scss'],
})
export class CenterInfoComponent {

  @Input()
  public icon: string;

  @Input()
  public title: string;

  @Input()
  public message: string;

  @Input()
  public height: string;

}
