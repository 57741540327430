// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 0.2rem 1rem;
  border-radius: var(--app-cards-border-radius);
  background-color: var(--app-cards-background);
  box-shadow: var(--app-cards-box-shadow);
}

.contact-info ion-skeleton-text {
  width: 9rem;
  line-height: 1rem;
}

.contact-actions {
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/contact-card-secondary/contact-card-secondary.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;EACA,6CAAA;EACA,6CAAA;EACA,uCAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":["section {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  overflow: hidden;\n  padding: .2rem 1rem;\n  border-radius: var(--app-cards-border-radius);\n  background-color: var(--app-cards-background);\n  box-shadow: var(--app-cards-box-shadow);\n}\n\n.contact-info ion-skeleton-text {\n  width: 9rem;\n  line-height: 1rem;\n}\n\n.contact-actions {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
