import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {

  private datePipe: DatePipe;

  constructor(
    private readonly translateService: TranslateService,
  ) {
    this.getDatePipe();
    this.translateService.onLangChange.subscribe(() => this.getDatePipe());
  }

  public transform(value: string | number | Date, format: string = 'mediumDate'): string {
    return this.datePipe.transform(value, format);
  }

  private getDatePipe(): void {
    let currentLang = this.translateService.currentLang;

    // Prefer EU date formatting
    if (currentLang === 'en-US') {
      currentLang = 'en-IE';
    }

    this.datePipe = new DatePipe(currentLang);
  }

}
