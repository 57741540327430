import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Contact, ContactList } from '@core/models/interfaces/api/contact.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactService {

  private readonly ENTITY_NAME = 'contacts';

  constructor(
    private readonly apiService: ApiService,
  ) { }

  public async getAll(parameters: object = {}): Promise<Contact[]> {
    return (await this.apiService.getItems(this.ENTITY_NAME, parameters)) as Contact[];
  }

  public async get(id: number, parameters: object = {}): Promise<Contact> {
    return (await this.apiService.getItem(this.ENTITY_NAME, id, parameters)) as Contact;
  }

  public async list(parameters: object = {}): Promise<ContactList> {
    return (await this.apiService.getSingletonItem(`${this.ENTITY_NAME}/list`, parameters)) as ContactList;
  }

  public async count(parameters: object = {}): Promise<number> {
    return this.apiService.getItemCount(this.ENTITY_NAME, parameters);
  }

}
