import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';

import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en-IE';

import { ComponentsModule } from '@shared/components/components.module';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: '__wocklum_db',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }),
        TranslateModule.forRoot(),
        CoreModule,
        ComponentsModule,
        PipesModule,
        AppRoutingModule, // Must be imported as the last module as it contains the fallback route
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
