import { Image, ImageResolution, imageResolutions } from '@core/models/datatypes/image.interface';
import { MediaFormat } from '@core/models/datatypes/media-format.interface';
import { apiSettings } from '@env/environment';

/**
 * Returns the image format with the highest resolution
 */
export function getBestImage(image: Image, maxRes?: ImageResolution): MediaFormat {
  const fromIndex: number = maxRes ? imageResolutions.indexOf(maxRes) : 0;

  for (let i = fromIndex; i < imageResolutions.length; i++) {
    if (image.formats[imageResolutions[i]]) {
      return image.formats[imageResolutions[i]];
    }
  }
}

export function getMediaFormatUrl(mediaFormat: MediaFormat): string {
  if (typeof mediaFormat === 'undefined') {
    return undefined;
  }

  return `${apiSettings.baseUrl}${mediaFormat.url}`;
}
