import { MediaFormat } from './media-format.interface';

export interface Image {
  /**
   * Received only from the API
   */
  readonly id: number;
  readonly name: string;
  readonly alternativeText: string;
  readonly caption: string;
  readonly width: number;
  readonly height: number;
  readonly formats: ImageFormats;
  readonly hash: string;
  readonly ext: string;
  readonly mime: string;
  readonly size: number;
  readonly url: string;
  readonly previewUrl?: string;
  readonly provider: string;
  readonly provider_metadata?: string;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface ImageFormats {
  readonly thumbnail?: MediaFormat;
  readonly large?: MediaFormat;
  readonly medium?: MediaFormat;
  readonly small?: MediaFormat;
}

export type ImageResolution = keyof ImageFormats;

export const imageResolutions: ImageResolution[] = [
  'large',
  'medium',
  'small',
  'thumbnail',
];
