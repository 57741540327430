import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import AuthenticationError from './AuthenticationError';

import { StorageService } from '@core/storage/storage.service';
import { apiSettings } from '@env/environment';
import { CapacitorHttp } from '@capacitor/core';

export interface LoginContext {
  identifier: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly COMPONENT_NAME = 'AuthenticationService';
  private readonly JWT_STORAGE_KEY = 'Token';

  constructor(
    private readonly navCtrl: NavController,
    private readonly storageService: StorageService,
  ) {}

  public async login(context: LoginContext): Promise<void> {
    const options = {
      url: `${apiSettings.baseUrl}/auth/local`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        identifier: context.identifier,
        password: context.password,
      },
      method: 'POST',
    };

    const response = await CapacitorHttp.request(options);

    if (response.status !== 200) {
      throw new AuthenticationError(response);
    }

    return this.setToken(response.data.jwt);
  }

  /**
   * Logs the user out.
   *
   * @returns boolean A Promise that resolves to true when navigation succeeds, to false when navigation fails, or is rejected on error.
   */
  public async logout(): Promise<boolean> {
    await this.removeToken();

    return this.navCtrl.navigateRoot('/login');
  }

  public async isAuthenticated(): Promise<boolean> {
    return !!(await this.getToken());
  }

  public async getToken(): Promise<string | undefined> {
    return this.storageService.get(this.COMPONENT_NAME, this.JWT_STORAGE_KEY);
  }

  public setToken(jwt: string): Promise<void> {
    return this.storageService.set(
      this.COMPONENT_NAME,
      this.JWT_STORAGE_KEY,
      jwt,
    );
  }

  public removeToken(): Promise<void> {
    return this.storageService.remove(
      this.COMPONENT_NAME,
      this.JWT_STORAGE_KEY,
    );
  }

  public async decodeToken(): Promise<JwtPayload | undefined> {
    const token = await this.getToken();

    if (!token) {
      return undefined;
    }

    return jwtDecode<JwtPayload>(token);
  }

  /**
   * Validate the login and logout the user if session expired
   *
   * @returns boolean Whether the session is valid
   */
  public async validateLogin(): Promise<boolean> {
    // Check whether the user appears to be logged in
    if (!await this.isAuthenticated()) {
      return false;
    }

    const sessionValid = await this.validateSession();

    // Log out user if the session is invalid
    if (!sessionValid) {
      await this.logout();
    }

    return sessionValid;
  }

  /**
   * Returns whether the session is still valid
   */
  public async validateSession(): Promise<boolean> {
    const token = await this.decodeToken();

    if (!token) {
      // No token was found
      return false;
    }

    const expiration = new Date(token.exp * 1000);

    // Set expiration date to the beginning of the day
    expiration.setHours(0);
    expiration.setMinutes(0);
    expiration.setSeconds(0);
    expiration.setMilliseconds(0);

    // Return whether the token is still valid
    return expiration.valueOf() > new Date().valueOf();
  }
}
