import { NgModule } from '@angular/core';
import { LocalizedDatePipe } from '@shared/pipes/localized-date/localized-date.pipe';

const APP_PIPES = [
  LocalizedDatePipe,
];

@NgModule({
  declarations: APP_PIPES,
  imports: [],
  exports: APP_PIPES,
})
export class PipesModule {}
