// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  overflow: hidden;
  border-radius: var(--app-cards-border-radius);
  background-color: var(--app-cards-background);
  box-shadow: var(--app-cards-box-shadow);
}
@media screen and (min-width: 335px) {
  section {
    grid-template-columns: clamp(6rem, 33%, 10rem) 1fr;
  }
}
section .portrait {
  width: 100%;
  height: 100%;
  max-height: 15rem;
  margin: 0;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 0;
}
section aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.4rem 1rem;
}
section aside h3 {
  margin-top: 0;
  font-weight: bolder;
}
section aside h3 ion-skeleton-text {
  width: 80%;
  line-height: 1.4rem;
}
section aside p {
  margin: 0;
}
section aside p ion-skeleton-text {
  width: 70%;
  line-height: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/contact-card/contact-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0BAAA;EACA,WAAA;EACA,gBAAA;EACA,6CAAA;EACA,6CAAA;EACA,uCAAA;AACF;AACE;EATF;IAUI,kDAAA;EAEF;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,SAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,yBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;AACN;AACM;EACE,UAAA;EACA,mBAAA;AACR;AAGI;EACE,SAAA;AADN;AAGM;EACE,UAAA;EACA,iBAAA;AADR","sourcesContent":["section {\n  display: grid;\n  grid-template-columns: 1fr;\n  width: 100%;\n  overflow: hidden;\n  border-radius: var(--app-cards-border-radius);\n  background-color: var(--app-cards-background);\n  box-shadow: var(--app-cards-box-shadow);\n\n  @media screen and (min-width: 335px) {\n    grid-template-columns: clamp(6rem, 33%, 10rem) 1fr;\n  }\n\n  .portrait {\n    width: 100%;\n    height: 100%;\n    max-height: 15rem;\n    margin: 0;\n    flex-shrink: 0;\n    object-fit: cover;\n    border-radius: 0;\n  }\n\n  aside {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 2rem 1.4rem 1rem;\n\n    h3 {\n      margin-top: 0;\n      font-weight: bolder;\n\n      ion-skeleton-text {\n        width: 80%;\n        line-height: 1.4rem;\n      }\n    }\n\n    p {\n      margin: 0;\n\n      ion-skeleton-text {\n        width: 70%;\n        line-height: 1rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
