import { inject, NgModule } from '@angular/core';
import { CanActivateFn, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@app/auth/authentication.guard';

const authGuard: CanActivateFn = (route, state) => {
  const authGuardService = inject(AuthenticationGuard);
  return authGuardService.canActivate(route, state);
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
